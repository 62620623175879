
<script setup lang="ts">
import { reactive, onMounted } from 'vue';
import { login, wechatCheck, getUserInfo,smssend,mobilelogin } from '@/apis';
import { ElMessage } from "element-plus";
import { getUserDeviceId } from "@/utils/device.js";
const emit = defineEmits(["success", "close"]);
let reg = /^1[3456789]\d{9}$/;
let reg1 = /^\d{6}$/;

const states = reactive({
    img:'',
    sign: '',
    token: null,
    timer: null,
    isWXLogin:true,
    phone:'',
    captcha:'',
    time:0
})

async function load(){
    const { data } = await login();
    states.img = data.url;
    states.sign = data.sign;
    states.timer = setInterval(async () =>{
        const d = await wechatCheck(states.sign, localStorage.getItem('from'),localStorage.getItem('bd_vid'),localStorage.getItem('qhclickid'))
        let token = d.data;
        if(token?.access_token){
            states.token = token;
            localStorage.setItem('token', JSON.stringify(token));
            clearInterval(states.timer);
            emit("success");
            if(token.is_new_user){
                window.uetq = window.uetq || [];
                window.uetq.push('event', 'register', {});
            }
        }else if(!d.status){
            ElMessage({
                message: d.message,
                type: "warning"
            });
        }
    }, 1000)
}

function close(){
    clearInterval(states.timer);
    emit("close");
}
function openArgeement(){
    window.open('/agreement');
}
onMounted(() =>{
    load();
})
function getCaptcha(e){
    e.stopPropagation()
    if(!states.phone){
        ElMessage({
          message: '请输入你的手机号',
          type: "warning"
        });
        return
    }
    if(!reg.test(states.phone)){
        ElMessage({
          message: '请输入正确的手机号',
          type: "warning"
        });
        return
    }
    smssend({mobile:states.phone}).then((d)=>{
        if(d.status){
            ElMessage({
            message: d.message,
            type: "success"
            });
        }else{
            ElMessage({
            message: d.message,
            type: "warning"
            });
        }
    })
    states.time = 60;
    setTime();
}
function setTime(){
    if(states.time!==0){
        states.time--;
        setTimeout(()=>{
            setTime()
        },1000)
    }
}
function submit(){
    if(!states.phone){
        ElMessage({
          message: '请输入你的手机号',
          type: "warning"
        });
        return
    }
    if(!states.captcha){
        ElMessage({
          message: '请输入验证码',
          type: "warning"
        });
        return
    }
    if(!reg.test(states.phone)){
        ElMessage({
          message: '请输入正确的手机号',
          type: "warning"
        });
        return
    }
    if(!reg1.test(states.captcha)){
        ElMessage({
          message: '请输入正确的验证码',
          type: "warning"
        });
        return
    }                        
    submitFn()
}
function submitFn(){
    mobilelogin({
        mobile:states.phone,
        code:states.captcha,
        platform:1,
        device_id:getUserDeviceId(),
        bd_vid:localStorage.getItem('bd_vid'),
        qhclickid:localStorage.getItem('qhclickid'),
        from:localStorage.getItem('from'),
    }).then((d)=>{
        if(d.status){
            localStorage.setItem('token',JSON.stringify(d.data))
            emit("success");
        }else{
            ElMessage({
                message: d.message,
                type: "warning"
            });
        }
    })
}
</script>

<template>
    <div class="login-wrap">
        <div class="login">
            <img src="./../assets/close.svg" @click="close" class="close" alt="">
            <img src="./../assets/logo-left.svg" class="logo-left" alt="">
            <div class="logo-text">轻竹办公</div>
            <div class="wechat">
                <img v-show="states.isWXLogin" src="./../assets/wechat.svg" alt="">
                {{ states.isWXLogin?'微信扫码 一键登录':'手机验证码登录' }}
            </div>
            <div v-show="states.isWXLogin" class="code">
                <img :src="states.img" alt="">
            </div>
            <div v-show="!states.isWXLogin" class="content">
                <el-input v-model="states.phone"  placeholder="请输入你的手机号" />
                <div class="input-container">
                    <el-input v-model="states.captcha"  placeholder="请输入验证码" />
                    <div @click="getCaptcha" class="input-btn" :class="{'input-btn-disabled':states.time}" >{{ states.time||'获取验证码' }}</div>
                </div>
                <div style="text-align: right;">
                    <el-popover placement="bottom" 
                        popper-style="border-radius: 10px;box-shadow: 0px 17px 32px 0px rgba(0,0,0,0.05);z-index:2060;"
                        :width="244" 
                        trigger="hover">
                        <template #reference>
                            <div  class="phone-error-btn">无法收到验证码？</div>
                        </template>
                        <img style="width: 220px;" src="@/assets/pay-service.png" alt="">
                    </el-popover>
                </div>
            </div>
            <el-button v-show="!states.isWXLogin" class="submit-btn"  type="primary" @click="submit" color="#52C443">登录</el-button>
            <div @click="states.isWXLogin = !states.isWXLogin" class="change-logion">{{ states.isWXLogin?'切换手机验证码登录':'切换微信扫码登录' }}</div>
            <div class="desc">
                登录即表示同意
                <span class="agreement" @click="openArgeement">《用户协议》、</span>
                <span class="agreement" @click="openArgeement">《隐私协议》</span>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.login-wrap{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.30);
    z-index: 2059;
    font-family: PingFang SC;
}
.change-logion{
    margin-bottom: 10px;
    cursor: pointer;
    color: #232922;
    margin-top: -10px;
}
.content{
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        margin-top: 30px;
        :deep(.el-input){
            width: 100%;
           .el-input__wrapper{
                width: 100%;
                height: 50px;
                line-height: 40px;
                font-size: 16px;
                box-sizing: border-box;
                border-radius: 8px;
                input{
                    width: 100%;
                }
            }
        }
        .input-container{
            margin-top: 10px;
            position: relative;
            .input-btn{
                cursor: pointer;
                position: absolute;
                right: 10px;
                top: 0px;
                height: 50px;
                line-height: 50px;
                font-size: 16px;
                color: #52c443;
                width: 100px;
                text-align: center;
                &.input-btn-disabled{
                    pointer-events: none;
                    color: rgba(68, 68, 68,0.7);
                }
            }
        }
    }
    .phone-error-btn{
        color: #b4b2b2dd;
        font-size: 14px;
        margin:10px 0 5px 0;
        cursor: pointer;
        display: inline-block;
    }
.submit-btn{
    height: 50px;
    width: calc(100% - 40px);
    font-size: 18px;
    margin: 10px 30px 30px 30px;
}
.login{
    width: 384px;
    height: 480px;
    flex-shrink: 0;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 17px 32px 0px rgba(0, 0, 0, 0.05);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.close{
    position: absolute;
    right: 16px;
    top: 16px;
    height: 30px;
    cursor: pointer;
}
.logo-left{
    height: 55px;
}
.logo-text{
    color: #232922;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 7px 0 8px 0;
}
.wechat{
    display: flex;
    align-items: center;
    color: #232922;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
    > img{
        height: 15px;
        margin-right: 4px;
    }
}
.code{
    width: 224px;
    height: 224px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #DDDEDC; 
    margin: 24px 0 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    > img{
        max-width: 98%;
        max-height: 98%;
    }
}
.desc{
    font-size: 14px;
}
.desc .agreement{
    color: var(--blue-blue-500, #298EFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}
</style>