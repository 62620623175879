<template>
  <div class="template-wrap" v-if="state.display">
    <div class="template-wrap-dialog">
      <header class="dialog-header">
        请在下方输入兑换码
        <img src="@/assets/close.svg" class="close" @click="close" alt="" />
      </header>
      <div class="inputBox">
        <el-input
          input-style="height:38px;"
          class="input"
          v-model="state.content"
          placeholder="输入兑换码"
          @keydown.enter="submit"
        />
        <div v-show="state.errorText" class="input-tips">{{ state.errorText }}</div>
        <el-button type="primary" :disabled="state.loading" @click="submit" class="inputBtn" color="#52C443">兑换
        </el-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { getUserInfo,codeRedeem } from '@/apis';
import { useGenerateStore } from "@/store/modules/generate";
const generateStore = useGenerateStore();
import {reactive,watch} from 'vue';
const emit = defineEmits(['success'])
const state = reactive({
    display:false,
    content:'',
    errorText:'',
    loading:false,
    timeout:false
})
const show = ()=>{
    state.loading = false;
    state.content = '';
    state.errorText = '';
    state.display = true;
}
watch(()=>state.content,()=>{
    state.errorText = '';
})
const close =()=>{
    state.display = false;
}
const submit = async()=>{
    if(!state.content){
        return
    }
    state.loading = true;
    clearTimeout(state.timeout); 
    try {
        const res  = await codeRedeem({
            code:state.content
        });
        if(res.status===1){
            const { data: info } = await getUserInfo();
            generateStore.changeUserInfo(info);
            ElMessage({
                message: res.message,
                type: "success",
            });
            emit('success')
            close();
        }else{
            state.errorText = res.message;
        }
        state.timeout = setTimeout(()=>{
            state.loading = false;
        },2000)
    } catch (error) {
        state.timeout = setTimeout(()=>{
            state.loading = false;
        },2000)
    }
}
defineExpose({
 show
})
</script>

<style lang="less" scoped>
.template-wrap {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  color: #000;
}
.template-wrap-dialog {
  position: absolute;
  width: 600px;
  // max-height: 80%;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  :deep(.el-input__wrapper){
    border-radius: 8px;
  }
  .inputBtn{
    border-radius: 8px;
    &.is-disabled path{
      fill:#8D908C;
    }
  }
}
.template-wrap-dialog .dialog-header {
  display: flex;
  position: relative;
  padding: 16px 28px;
  // border-bottom: 1px solid #dddedc;
  background: #fff;
  border-radius: 16px 16px 0 0;
  //styleName: 中标题加粗;
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 500;
}
.dialog-header .close {
  font-size: 22px;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}
.inputBox {
  display: flex;
  padding:5px 24px 18px;
  box-sizing: border-box;
  position: relative;
  align-items: flex-end;
  flex-flow: column wrap;
  .input-tips{
    width: 100%;
    height: 17px;
    color: rgba(255, 81, 89, 1);
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    margin-top: 2px;
  }
  :deep(.el-button){
        margin-top: 30px;
        width: 96px;
        margin-left: 8px;
        height: 38px;
        border-radius: 8px;
        background: rgba(82, 196, 67, 1);
        color:#fff;
        border:none;
        &:hover{
            background: rgba(82, 196, 67, 0.8);
        }
    }
}
</style>